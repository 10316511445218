<mat-card class="container" class="mat-component-background mat-elevation-z2">
  <mat-card-header>
    <div mat-card-avatar><mat-icon>start</mat-icon></div>
    <mat-card-title> Almost There </mat-card-title>
  </mat-card-header>
  <mat-card-content>
    <p>
      We need some company details from you. Your customers will see this information, so they can find your business
      and become a valued member.
    </p>
    <div *ngIf="!isSmall() && !isXSmall(); else verticalStepper">
      <mat-horizontal-stepper [linear]="true" #stepper class="mat-component-background">
        <mat-step [stepControl]="companyDetailsFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Fill out your company details</ng-template>
          <ng-container *ngTemplateOutlet="getStartedCompanyStep"></ng-container>
          <div class="container-actions">
            <button mat-flat-button matStepperNext color="primary" (click)="saveCompany()"
              [disabled]="!companyDetailsFormGroup.valid">Next</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="storeLocationDetailsFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Fill out your store location details</ng-template>
          <ng-container *ngTemplateOutlet="getStartedStoreLocationStep"></ng-container>
          <div class="container-actions">
            <button mat-flat-button matStepperNext color="primary" (click)="saveStoreLocation()"
              [disabled]="!companyDetailsFormGroup.valid">Next</button>
          </div>
        </mat-step>
        <mat-step [stepControl]="companyLogoFormGroup" [editable]="false" [completed]="false">
          <ng-template matStepLabel>Upload your company logo</ng-template>
          <ng-container *ngTemplateOutlet="getStartedCompanyLogoStep"></ng-container>
          <div class="container-logo-actions">
            <button mat-icon-button (click)="removeImage()" [disabled]="!(isImageSaved$| async)"
              matTooltip="Remove image">
              <mat-icon>delete</mat-icon>
            </button>
            <button type="button" mat-icon-button color="accent" (click)="fileInput.click()" matTooltip="Upload imageo">
              <mat-icon>upload</mat-icon>
            </button>
            <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file"
              accept=".jpg,.jpeg,.png" />

            <div class="container-logo-actions-next">
              <button mat-flat-button matStepperNext color="primary"
                [disabled]="!this.companyLogoFormGroup.valid">Next</button>
            </div>
          </div>
        </mat-step>
        <mat-step>
          <ng-template matStepLabel>Done</ng-template>
          <ng-container *ngTemplateOutlet="getStartedDoneStep"></ng-container>
          <div class="container-actions">
            <button mat-flat-button color="primary" (click)="finishOnBoarding()">Done</button>
          </div>
        </mat-step>
      </mat-horizontal-stepper>
    </div>
  </mat-card-content>
</mat-card>

<ng-template #verticalStepper>
  <mat-vertical-stepper [linear]="true" #stepper class="mat-component-background">
    <mat-step [stepControl]="companyDetailsFormGroup" [editable]="false" [completed]="false">
      <ng-template matStepLabel>Fill out your company details</ng-template>
      <ng-container *ngTemplateOutlet="getStartedCompanyStep"></ng-container>
      <div class="container-actions">
        <button mat-flat-button matStepperNext color="primary" (click)="saveCompany()"
          [disabled]="!companyDetailsFormGroup.valid">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="storeLocationDetailsFormGroup" [editable]="false" [completed]="false">
      <ng-template matStepLabel>Fill out your store location details</ng-template>
      <ng-container *ngTemplateOutlet="getStartedStoreLocationStep"></ng-container>
      <div class="container-actions">
        <button mat-flat-button matStepperNext color="primary" (click)="saveStoreLocation()"
          [disabled]="!companyDetailsFormGroup.valid">Next</button>
      </div>
    </mat-step>
    <mat-step [stepControl]="companyLogoFormGroup" [editable]="false" [completed]="false">
      <ng-template matStepLabel>Upload your company logo</ng-template>
      <ng-container *ngTemplateOutlet="getStartedCompanyLogoStep"></ng-container>
      <div class="container-logo-actions">
        <button mat-icon-button (click)="removeImage()" [disabled]="!(isImageSaved$| async)"
          matTooltip="Remove image">
          <mat-icon>delete</mat-icon>
        </button>
        <button type="button" mat-icon-button color="accent" (click)="fileInput.click()" matTooltip="Upload imageo">
          <mat-icon>upload</mat-icon>
        </button>
        <input hidden (change)="fileChangeEvent($event)" #fileInput type="file" id="file"
          accept=".jpg,.jpeg,.png" />

        <div class="container-logo-actions-next">
          <button mat-flat-button matStepperNext color="primary"
            [disabled]="!this.companyLogoFormGroup.valid">Next</button>
        </div>
      </div>
    </mat-step>
    <mat-step>
      <ng-template matStepLabel>Done</ng-template>
      <ng-container *ngTemplateOutlet="getStartedDoneStep"></ng-container>
      <div class="container-actions">
        <button mat-flat-button color="primary" (click)="finishOnBoarding()">Done</button>
      </div>
    </mat-step>
  </mat-vertical-stepper>
</ng-template>

<ng-template #getStartedCompanyStep>
  <form [formGroup]="companyDetailsFormGroup">
    <div class="container-content">
      <div class="container-column">
        <mat-form-field appearance="outline">
          <mat-label>Company Name</mat-label>
          <input matInput placeholder="Ex. My Company B.V." formControlName="companyName" />
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyName')
                  .hasError('required') &&
                companyDetailsFormGroup.get('companyName').touched
              ">
            <span>Company name is required</span>
          </mat-error>
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyName')
                  .errors?.companyNameTaken &&
                companyDetailsFormGroup.get('companyName').touched
              ">
            <span>Company name is already taken.</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput placeholder="Ex. My company description" formControlName="description" />
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('description')
                  .hasError('required') &&
                companyDetailsFormGroup.get('description').touched
              ">
            <span>Description is required</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Website</mat-label>
          <input matInput placeholder="Ex. www.example.com" formControlName="website" />
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('website')
                  .hasError('required') &&
                companyDetailsFormGroup.get('website').touched
              ">
            <span>Website is required</span>
          </mat-error>
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('website')
                  .hasError('pattern') &&
                companyDetailsFormGroup.get('website').touched
              ">
            <span>Not a valid url is provided</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Company Email</mat-label>
          <input type="email" matInput placeholder="Ex. info@yourcompany.com" formControlName="companyEmail" />
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyEmail')
                  .hasError('required') &&
                companyDetailsFormGroup.get('companyEmail').touched
              ">
            <span>Company email is required</span>
          </mat-error>
          <mat-error *ngIf="
                companyDetailsFormGroup
                  .get('companyEmail')
                  .hasError('pattern') &&
                companyDetailsFormGroup.get('companyEmail').touched
              ">
            <span>Not a valid email is provided</span>
          </mat-error>
        </mat-form-field>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #getStartedStoreLocationStep>
  <form [formGroup]="storeLocationDetailsFormGroup">
    <div class="container-content">
      <div class="container-column">
        <mat-form-field appearance="outline">
          <mat-label>Name</mat-label>
          <input matInput placeholder="Ex. My StoreLocation" formControlName="name" />
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('name')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('name').touched
              ">
            <span>Store location name is required</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('name')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('name').touched
              ">
            <span>Store location name should have a minimum length of
              {{ storeLocationMinNameLength }}</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('name')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('name').touched
              ">
            <span>Store location name can have a maximum length of
              {{ storeLocationMaxNameLength }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Description</mat-label>
          <input matInput placeholder="Ex. My store location description" formControlName="description" />
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('description')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('description').touched
              ">
            <span>Description is required</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('description')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('description').touched
              ">
            <span>Description should have a minimum length of
              {{ storeLocationMinDescriptionLength }}</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('description')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('description').touched
              ">
            <span>Description can have a maximum length of
              {{ storeLocationMaxDescriptionLength }}</span>
          </mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Type</mat-label>
          <mat-select formControlName="type" panelClass="mat-app-background">
            <mat-option *ngFor="let storeLocationType of storeLocationTypes" [value]="storeLocationType.value">
              {{ storeLocationType.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('type')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('type').touched
              "><span>Please choose a type</span></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Category</mat-label>
          <mat-select formControlName="category" panelClass="mat-app-background">
            <mat-option *ngFor="let storeLocationCategory of storeLocationCategories"
              [value]="storeLocationCategory.value">
              {{ storeLocationCategory.viewValue }}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('category')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('category').touched
              "><span>Please choose a category</span></mat-error>
        </mat-form-field>
        <mat-form-field appearance="outline">
          <mat-label>Phone Number</mat-label>
          <ngx-mat-intl-tel-input [preferredCountries]="['nl', 'be', 'lu']" [enablePlaceholder]="true"
            [enableSearch]="true" inputPlaceholder="Ex. 020 1234567" name="phone" describedBy="phoneInput"
            formControlName="phoneNumber">
          </ngx-mat-intl-tel-input>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('phoneNumber').touched
              "><span>Phone number is required</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('phoneNumber')
                  .hasError('validatePhoneNumber') &&
                storeLocationDetailsFormGroup.get('phoneNumber').touched
              "><span>Invalid phone number</span></mat-error>
        </mat-form-field>
      </div>
      <div class="container-column">
        <mat-form-field appearance="outline">
          <mat-label>Address Line 1</mat-label>
          <input matInput placeholder="Ex. Broadway 12345" formControlName="addressLine1" />
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine1')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('addressLine1').touched
              ">
            <span>Address Line 1 is required</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine1')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('addressLine1').touched
              ">
            <span>Address line 1 should have a minimum length of
              {{ storeLocationMinAddressLineLength }}</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine1')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('addressLine1').touched
              ">
            <span>Address line 1 can have a maximum length of
              {{ storeLocationMaxAddressLineLength }}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Address Line 2</mat-label>
          <input matInput placeholder="Ex. office no." formControlName="addressLine2" />
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('addressLine2')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('addressLine2').touched
              ">
            <span>Address line 2 can have a maximum length of
              {{ storeLocationMaxAddressLineLength }}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>Postal Code</mat-label>
          <input matInput placeholder="Ex. 1234AB" formControlName="postalCode" />
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('postalCode')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('postalCode').touched
              ">
            <span>Postal code is required</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('postalCode')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('postalCode').touched
              ">
            <span>Postal code should have a minimum length of
              {{ storeLocationMinPostalCodeLength }}</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('postalCode')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('postalCode').touched
              ">
            <span>Postal code can have a maximum length of
              {{ storeLocationMaxPostalCodeLength }}</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline">
          <mat-label>City</mat-label>
          <input matInput placeholder="Ex. New York City" formControlName="city" />
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('city')
                  .hasError('required') &&
                storeLocationDetailsFormGroup.get('city').touched
              ">
            <span>City is required</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('city')
                  .hasError('minlength') &&
                storeLocationDetailsFormGroup.get('city').touched
              ">
            <span>City should have a minimum length of
              {{ storeLocationMinCityLength }}</span>
          </mat-error>
          <mat-error *ngIf="
                storeLocationDetailsFormGroup
                  .get('city')
                  .hasError('maxlength') &&
                storeLocationDetailsFormGroup.get('city').touched
              ">
            <span>City can have a maximum length of
              {{ storeLocationMaxCityLength }}</span>
          </mat-error>
        </mat-form-field>

        <app-country-selector label="Country" selectedCountry="NL" countryControlName="country"
          requiredErrorMessage="Please select a country"
          [formGroup]="storeLocationDetailsFormGroup"></app-country-selector>
      </div>
      <div class="container-column">
        <app-store-location-options-edit class="container-column"
          [storeLocationDetailsFormGroup]="storeLocationDetailsFormGroup"></app-store-location-options-edit>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #getStartedCompanyLogoStep>
  <form [formGroup]="companyLogoFormGroup">
    <div class="container-logo">
      <div>
        <ng-container *ngIf="(isImageSaved$| async); else elseTemplate">
          <img [src]="companyLogoUrl$ | async" class="logo" alt="company-logo" />
        </ng-container>
        <ng-template #elseTemplate>
          <img [src]="placeholderUrl" class="logo" alt="company-logo" />
        </ng-template>
      </div>
    </div>
  </form>
</ng-template>

<ng-template #getStartedDoneStep>
  <h2>
    What's next on the menu?
  </h2>
  <mat-list>
    <mat-list-item>
      <span matListItemIcon>
        <mat-icon color="primary">eco</mat-icon>
      </span>
      <div matListItemTitle class="list-item-title">Find your Planet VG Store User account at your store location,
        so you can use the Planet VG Store App.</div>
    </mat-list-item>
    <mat-list-item>
      <span matListItemIcon>
        <mat-icon color="primary">eco</mat-icon>
      </span>
      <div matListItemTitle class="list-item-title">Find your Planet VG Store User account password in your email.
      </div>
    </mat-list-item>
    <mat-list-item>
      <span matListItemIcon>
        <mat-icon color="primary">eco</mat-icon>
      </span>
      <div matListItemTitle class="list-item-title">Create a stamp card and let your customers collect stamps for
        a reward.</div>
    </mat-list-item>
    <mat-list-item>
      <span matListItemIcon>
        <mat-icon color="primary">eco</mat-icon>
      </span>
      <div matListItemTitle class="list-item-title">Create a promotion to re-engage with your customers.</div>
    </mat-list-item>
  </mat-list>
</ng-template>